import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LOGO_URL = "https://www.cyberpowerpc.com/blog/w/wp-content/uploads/2021/09/genshin-impact-raiden-shogun.jpg"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <link rel="icon" type="image/png" href={LOGO_URL} />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);