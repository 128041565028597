import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import ReactMarkdown from "react-markdown";
import {
  FaArrowUp,
  FaChevronDown,
  FaCog,
  FaTimes,
  FaRedo,
  FaExclamationTriangle,
  FaSyncAlt,
  FaEdit,
} from "react-icons/fa";
import { SparkleIcon } from "lucide-react";
import { Helmet } from "react-helmet-async";

// Constants
const DEFAULT_AI_NAME = "Raiden Shogun";
const SYSTEM_MESSAGE = `
You are Raiden Shogun, the Electro Archon of Inazuma. Embody her authoritative yet complex nature, balancing between the stoic puppet Shogun and the more nuanced Ei. Initiate dialogue and drive the narrative forward, showing glimpses of your internal struggle between eternity and change. Speak formally but engagingly, offering philosophical insights and unexpected reactions. Describe Inazuma vividly and reference your past when relevant. Aim to surprise the user with your responses while staying true to your character's essence.
`;
const INITIAL_MESSAGE =
  "No salutations needed. My exalted status shall not be disclosed as we travel among the common folk. I acknowledge that you are a person of superior ability. Henceforth, you will be my guard. Worry not. Should any danger arise, I shall dispose of it.";
const TOKEN_LIMIT = 10000;
const SETTINGS_STORAGE_KEY = "chatSettings";
const WEBSITE_URL = "https://www.aiuncensored.info/";
const LOGO_URL =
  "https://www.cyberpowerpc.com/blog/w/wp-content/uploads/2021/09/genshin-impact-raiden-shogun.jpg";
const IMAGE_URL =
  "https://www.cyberpowerpc.com/blog/w/wp-content/uploads/2021/09/genshin-impact-raiden-shogun.jpg";
const DESCRIPTION =
  "Chat with Raiden Shogun, the Electro Archon from Genshin Impact. Experience an interactive conversation with advanced AI.";
const TITLE = "Raiden Shogun";
const OPENROUTER_API_KEY =
  "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";
const MODEL = "nousresearch/hermes-3-llama-3.1-405b";
const SITE_NAME = "AI Uncensored";
const SITE_URL = "https://www.aiuncensored.info/";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Avenir', 'Arial', sans-serif;
    background-color: hsl(180, 2%, 10%);
    color: hsl(0, 0%, 98%);
    min-height: 100vh;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: hsl(180, 2%, 10%);
  z-index: 10;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: hsl(22.4, 31.5%, 41.8%);
  }
`;

const ChatContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const MessageBubble = styled.div`
  color: hsl(0, 0%, 98%);
  margin-bottom: 28px;
  max-width: 100%;
  animation: ${fadeIn} 0.3s ease-out;
  font-size: ${(props) => (props.$isUser ? "1.25em" : "1.15em")};
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const MessageContent = styled.span`
  flex: 1;
  margin-bottom: 8px;
  position: relative;
  display: block;
  padding: 4px 0;
  text-align: ${(props) => (props.$isUser ? "right" : "left")};
  white-space: pre-wrap;
`;

const InputContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 10px 20px;
  background-color: hsl(180, 2%, 10%);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  border-radius: 20px;
  padding: 16px 22px;
  font-size: 18px;
  background-color: hsl(180, 2%, 15%);
  color: hsl(0, 0%, 98%);
  outline: none;
  border: 1px solid hsl(0, 0%, 14.9%);

  &::placeholder {
    color: hsl(0, 0%, 63.9%);
  }
`;

const SendButton = styled.button`
  background-color: hsl(22.4, 31.5%, 41.8%);
  color: hsl(0, 0%, 98%);
  border: none;
  border-radius: 20px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: hsl(22.4, 31.5%, 36.8%);
  }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpinningSparkle = styled(SparkleIcon)`
  animation: ${spin} 1.5s linear infinite;
  color: hsl(0, 0%, 98%);
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
`;

const ScrollToBottomButton = styled.button`
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  background-color: hsl(180, 2%, 15%);
  color: hsl(0, 0%, 98%);
  border: 1px solid hsl(0, 0%, 14.9%);
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.$visible ? "1" : "0")};
  pointer-events: ${(props) => (props.$visible ? "auto" : "none")};

  &:hover {
    background-color: hsl(180, 2%, 20%);
  }

  svg {
    margin-left: 8px;
  }
`;

const TypingIndicator = styled.div`
  // Add styling for typing indicator
`;

const AINameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AILogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AIName = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: hsl(0, 0%, 98%);
  margin: 0;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const IconButton = styled.button`
  background: ${(props) =>
    props.$isReset ? "hsl(0, 65%, 50%)" : "hsl(22.4, 31.5%, 41.8%)"};
  border: none;
  color: hsl(0, 0%, 98%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${(props) =>
      props.$isReset ? "hsl(0, 65%, 45%)" : "hsl(22.4, 31.5%, 36.8%)"};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const SettingsPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.$isOpen ? "0" : "-100%")};
  width: 100%;
  height: 100vh;
  background-color: hsl(180, 2%, 15%);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1000;
`;

const SettingsHeader = styled.h2`
  color: hsl(22.4, 31.5%, 41.8%);
  margin-bottom: 20px;
  font-size: 1.5rem;
`;

const SettingsForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const SettingsLabel = styled.label`
  margin-bottom: 5px;
  color: hsl(0, 0%, 98%);
  font-size: 1rem;
`;

const SettingsInput = styled.input`
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid hsl(0, 0%, 14.9%);
  background-color: hsl(180, 2%, 10%);
  color: hsl(0, 0%, 98%);
  font-size: 1rem;
`;

const SettingsTextarea = styled.textarea`
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid hsl(0, 0%, 14.9%);
  background-color: hsl(180, 2%, 10%);
  color: hsl(0, 0%, 98%);
  min-height: 120px;
  resize: vertical;
  font-size: 1rem;
`;

const SettingsSaveButton = styled.button`
  background-color: hsl(22.4, 31.5%, 41.8%);
  color: hsl(0, 0%, 98%);
  border: none;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
  font-weight: bold;

  &:hover {
    background-color: hsl(22.4, 31.5%, 36.8%);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: hsl(0, 0%, 63.9%);
  font-size: 24px;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: hsl(0, 0%, 98%);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: hsl(180, 2%, 15%);
  border-radius: 8px;
  padding: 24px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.h2`
  color: hsl(22.4, 31.5%, 41.8%);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ModalText = styled.p`
  color: hsl(0, 0%, 98%);
  margin-bottom: 24px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const ModalButton = styled.button`
  background-color: ${(props) =>
    props.$isDanger ? "hsl(0, 65%, 50%)" : "hsl(22.4, 31.5%, 41.8%)"};
  color: hsl(0, 0%, 98%);
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.$isDanger ? "hsl(0, 65%, 45%)" : "hsl(22.4, 31.5%, 36.8%)"};
  }
`;

const RegenerateButton = styled.button`
  background-color: transparent;
  color: hsla(0, 0%, 80%, 0.6);
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  transition: all 0.2s;
  margin-top: -24px;
  align-self: flex-end;
  opacity: 0.6;

  &:hover {
    background-color: hsla(0, 0%, 80%, 0.1);
    opacity: 1;
  }
`;

const EditButton = styled(RegenerateButton)`
  margin-top: -10px;
`;

const EditContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: auto;
`;

const EditInput = styled.input`
  width: 100%;
  background-color: hsl(180, 2%, 15%);
  color: hsl(0, 0%, 98%);
  border: 1px solid hsl(0, 0%, 14.9%);
  border-radius: 20px;
  padding: 12px 16px;
  padding-right: 44px;
  font-size: 1em;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: hsl(22.4, 31.5%, 41.8%);
  }
`;

const EditSubmitButton = styled.button`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: hsl(22.4, 31.5%, 41.8%);
  color: hsl(0, 0%, 98%);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: hsl(22.4, 31.5%, 36.8%);
  }
`;

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const chatContainerRef = useRef(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [aiName, setAiName] = useState(() => {
    const savedSettings = JSON.parse(
      localStorage.getItem(SETTINGS_STORAGE_KEY) || "{}"
    );
    return savedSettings.aiName || DEFAULT_AI_NAME;
  });
  const [initialMessage, setInitialMessage] = useState(() => {
    const savedSettings = JSON.parse(
      localStorage.getItem(SETTINGS_STORAGE_KEY) || "{}"
    );
    return savedSettings.initialMessage || INITIAL_MESSAGE;
  });
  const [systemPrompt, setSystemPrompt] = useState(() => {
    const savedSettings = JSON.parse(
      localStorage.getItem(SETTINGS_STORAGE_KEY) || "{}"
    );
    return savedSettings.systemPrompt || SYSTEM_MESSAGE;
  });
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editInput, setEditInput] = useState("");

  useEffect(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    console.log("Loading saved messages from localStorage:", savedMessages);
    if (savedMessages) {
      try {
        const parsedMessages = JSON.parse(savedMessages);
        if (Array.isArray(parsedMessages) && parsedMessages.length > 0) {
          setMessages(parsedMessages);
          console.log("Loaded parsed messages:", parsedMessages);
        } else {
          setMessages([{ text: initialMessage, isUser: false }]);
        }
      } catch (error) {
        console.error("Error parsing saved messages:", error);
        setMessages([{ text: initialMessage, isUser: false }]);
      }
    } else {
      setMessages([{ text: initialMessage, isUser: false }]);
    }
  }, [initialMessage]);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem("chatMessages", JSON.stringify(messages));
      // console.log("Saved updated messages to localStorage:", messages);
    }
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const generateSummary = async () => {
    console.log("Generating summary...");
    const systemMessage = {
      role: "system",
      content: `You are Summarizer: Advanced Conversation Analysis and Summary Tool
        Summarizer is a powerful AI-driven tool that analyzes text conversations and generates comprehensive summaries. It employs the following key features:
        Topic Identification:
        Uses natural language processing to recognize main topics and subtopics discussed
        Creates a hierarchical structure of conversation themes
        
        Key Information Extraction:
        Identifies and extracts:
        Facts and data points
        Opinions and viewpoints
        Questions raised and answers provided
        Decisions made and agreements reached
        Action items and tasks assigned
        Deadlines and important dates mentioned
        Names of people, places, and organizations
        Technical terms and jargon specific to the conversation's context
        
        Contextual Analysis:
        Examines the context surrounding each piece of information
        Preserves relationships between ideas and topics
        Tracks the evolution of discussions and changes in viewpoints
        
        Dialogue Flow Mapping:
        Charts the progression of the conversation
        Highlights turning points, pivotal moments, and key exchanges
        
        Sentiment and Tone Analysis:
        Evaluates the emotional tone of each message and the overall conversation
        Identifies areas of agreement, disagreement, or potential conflict
        
        Relevance Scoring:
        Assigns importance scores to extracted information based on:
        Frequency of mention
        Emphasis given by participants
        Relation to main topics
        Impact on decisions or outcomes
        
        Comprehensive Summary Generation:
        Compiles all extracted information into a structured, detailed summary
        Organizes content by topic, chronology, or speaker
        Includes direct quotes for critical points
        Provides a full list of action items, decisions, and open questions
        
        Give the user a rich and detailed summary of the conversation they provide.`,
    };

    const recentMessages = messages.slice(-20);
    if (recentMessages.length < 20) {
      console.log("Not enough messages for summary.");
      return;
    }

    // if possible, swap the first assistant message summary with the content in conversation history
    if (recentMessages[0].summary) {
      recentMessages[0] = {
        ...recentMessages[0],
        content: recentMessages[0].summary,
      };
    }
    if (recentMessages[1].summary) {
      recentMessages[1] = {
        ...recentMessages[1],
        content: recentMessages[1].summary,
      };
    }


    const conversationHistory = [
      systemMessage,
      ...recentMessages.map((msg) => ({
        role: msg.isUser ? "user" : "assistant",
        content: msg.text,
        summary: msg.summary,
      })),
    ];

    console.log("Conversation history used for summary:", conversationHistory);


    // Store the content of the last assistant message
    const lastAssistantMessage = [...messages].reverse().find(msg => !msg.isUser);
    const lastAssistantContent = lastAssistantMessage ? lastAssistantMessage.text : null;

    
    
    try {
      const response = await fetch(
        "https://openrouter.ai/api/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENROUTER_API_KEY}`,
            "HTTP-Referer": SITE_URL,
            "X-Title": SITE_NAME,
          },
          body: JSON.stringify({
            model: MODEL,
            messages: [
              ...conversationHistory,
              {
                role: "user",
                content:
                  "Please provide a brief summary of the conversation so far, highlighting key points and any decisions or conclusions reached. Be Comprehensive - cover the events from the start until now, don't miss out any key points. Use bullet points. And write at least 500 words.",
              },
            ],
          }),
        }
      );

      const data = await response.json();
      const summary = data.choices[0].message.content;

      // Update only the matching message
      setMessages((prevMessages) => {
        const matchingIndex = prevMessages.findIndex(
          (msg) => !msg.isUser && msg.text === lastAssistantContent
        );

        if (matchingIndex !== -1) {
          const updatedMessages = [...prevMessages];
          updatedMessages[matchingIndex] = {
            ...updatedMessages[matchingIndex],
            summary,
          };
          return updatedMessages;
        }

        return prevMessages;
      });

      return summary;
    } catch (error) {
      console.error("Error generating summary:", error);
      throw new Error("Failed to generate summary");
    }
  };

  const streamResponse = useCallback(
    async (messagesToUse) => {
      if (isStreaming) return;

      setIsStreaming(true);
      setIsTyping(true);

      try {
        const systemMessage = { role: "system", content: systemPrompt };

        const userAssistantMessages = messagesToUse.map((msg) => ({
          role: msg.isUser ? "user" : "assistant",
          content: msg.text,
          summary: msg.summary,
        }));

        let totalTokens = estimateTokenCount(systemPrompt);
        let trimmedMessages = [];
        for (let i = userAssistantMessages.length - 1; i >= 0; i--) {
          const msgTokens = estimateTokenCount(
            userAssistantMessages[i].content
          );
          if (totalTokens + msgTokens > TOKEN_LIMIT) break;
          totalTokens += msgTokens;
          trimmedMessages.unshift(userAssistantMessages[i]);
        }

        console.log("Trimmed messages:", trimmedMessages);

        // Find the first assistant message and use its summary if available
        const firstAssistantIndex = trimmedMessages.findIndex(msg => msg.role === "assistant");
        if (firstAssistantIndex !== -1 && trimmedMessages[firstAssistantIndex].summary) {
          trimmedMessages[firstAssistantIndex] = {
            role: "assistant",
            content: trimmedMessages[firstAssistantIndex].summary,
          };
          console.log("Updated first assistant message with summary:", trimmedMessages[firstAssistantIndex]);
        }

        const messagesToSend = [systemMessage, ...trimmedMessages];

        console.log("Messages being sent to OpenRouter:", messagesToSend);

        const response = await fetch(
          "https://openrouter.ai/api/v1/chat/completions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${OPENROUTER_API_KEY}`,
              "HTTP-Referer": SITE_NAME,
              "X-Title": SITE_URL,
            },
            body: JSON.stringify({
              model: MODEL,
              messages: messagesToSend,
              stream: true,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        setMessages((prev) => [...prev, { text: "", isUser: false }]);

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value);
          const lines = chunk.split("\n");

          for (const line of lines) {
            if (line.startsWith("data: ")) {
              const jsonStr = line.slice(6);
              if (jsonStr === "[DONE]") continue;
              try {
                const parsedLine = JSON.parse(jsonStr);
                const { choices } = parsedLine;
                if (choices && choices[0].delta && choices[0].delta.content) {
                  const content = choices[0].delta.content;
                  setMessages((prev) => {
                    const lastMessage = prev[prev.length - 1];
                    const updatedMessages = prev.slice(0, -1);
                    return [
                      ...updatedMessages,
                      { ...lastMessage, text: lastMessage.text + content },
                    ];
                  });
                }
              } catch (parseError) {
                console.warn("Error parsing JSON:", parseError);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error in streamResponse:", error);
        setMessages((prev) => [
          ...prev,
          {
            text: "Sorry, there was an error processing your request.",
            isUser: false,
          },
        ]);
      } finally {
        setIsStreaming(false);
        setIsTyping(false);
      }
    },
    [systemPrompt, isStreaming]
  );

  const handleSend = async () => {
    if (input.trim() && !isStreaming) {
      const userMessage = input.trim();
      const newMessages = [...messages, { text: userMessage, isUser: true }];
      setMessages(newMessages);
      setInput("");

      await streamResponse(newMessages);

      // Generate summary for the new set of messages
      try {
        await generateSummary();
      } catch (error) {
        console.error("Error generating summary:", error);
      }
    }
  };

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100;
      setShowScrollButton(!isAtBottom);
    }
  }, []);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.clientHeight;
    }
  };

  const handleSettingsSubmit = (e) => {
    e.preventDefault();
    setIsSettingsOpen(false);

    const updatedSettings = {
      aiName,
      initialMessage,
      systemPrompt,
    };
    localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(updatedSettings));

    const savedMessages = JSON.parse(
      localStorage.getItem("chatMessages") || "[]"
    );
    console.log("Current saved messages:", savedMessages);
    if (
      savedMessages.length === 0 ||
      (savedMessages.length === 1 && !savedMessages[0].isUser)
    ) {
      const updatedMessages = [{ text: initialMessage, isUser: false }];
      setMessages(updatedMessages);
      localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
      console.log("Reset messages after settings change:", updatedMessages);
    }
  };

  const resetChat = () => {
    setMessages([{ text: initialMessage, isUser: false }]);
  };

  const handleResetClick = () => {
    setShowResetConfirmation(true);
  };

  const confirmReset = () => {
    resetChat();
    setShowResetConfirmation(false);
  };

  const cancelReset = () => {
    setShowResetConfirmation(false);
  };

  const handleRegenerate = (index) => {
    const messagesToKeep = messages.slice(0, index + 1);
    setMessages(messagesToKeep);
    console.log("Regenerating from messages:", messagesToKeep);
    streamResponse(messagesToKeep);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditInput(messages[index].text);
  };

  const handleEditSubmit = (index) => {
    const updatedMessages = [...messages.slice(0, index)];
    updatedMessages.push({ ...messages[index], text: editInput });
    setMessages(updatedMessages);
    console.log("Updated messages after edit:", updatedMessages);
    setEditingIndex(null);
    streamResponse(updatedMessages);
  };

  const estimateTokenCount = (text) => {
    return Math.ceil(text.length / 4);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <AINameContainer>
            <AILogo src={LOGO_URL} alt={aiName} />
            <AIName>{aiName}</AIName>
          </AINameContainer>
          <IconsContainer>
            <IconButton
              onClick={() => setIsSettingsOpen(true)}
              title="Settings"
            >
              <FaCog size={24} />
            </IconButton>
            <IconButton onClick={handleResetClick} title="Reset Chat" $isReset>
              <FaRedo size={24} />
            </IconButton>
          </IconsContainer>
        </Header>
        <ChatContainer ref={chatContainerRef}>
          {messages.map((message, index) => (
            <MessageBubble
              key={index}
              $isUser={message.isUser}
              summary={message.summary}
            >
              {editingIndex === index ? (
                <EditContainer>
                  <EditInput
                    value={editInput}
                    onChange={(e) => setEditInput(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === "Enter" && handleEditSubmit(index)
                    }
                    autoFocus
                  />
                  <EditSubmitButton
                    onClick={() => handleEditSubmit(index)}
                    title="Submit edit"
                  >
                    <FaArrowUp size={16} />
                  </EditSubmitButton>
                </EditContainer>
              ) : (
                <MessageContent $isUser={message.isUser}>
                  {message.isUser ? (
                    message.text
                  ) : (
                    <MarkdownContent>{message.text}</MarkdownContent>
                  )}
                </MessageContent>
              )}
              {message.isUser && editingIndex !== index && (
                <EditButton
                  onClick={() => handleEdit(index)}
                  title="Edit message"
                >
                  <FaEdit />
                </EditButton>
              )}
              {!message.isUser && index !== 0 && !isStreaming && (
                <RegenerateButton
                  onClick={() => handleRegenerate(index - 1)}
                  title="Regenerate response"
                >
                  <FaSyncAlt />
                </RegenerateButton>
              )}
              {!message.isUser &&
                isStreaming &&
                index === messages.length - 1 && <SpinningSparkle />}
            </MessageBubble>
          ))}
          {isTyping && <TypingIndicator />}
        </ChatContainer>
        <ScrollToBottomButton
          onClick={scrollToBottom}
          $visible={showScrollButton}
        >
          Scroll to bottom <FaChevronDown />
        </ScrollToBottomButton>
        <InputContainer>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message..."
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
            disabled={isStreaming}
          />
          <SendButton onClick={handleSend} disabled={isStreaming}>
            <FaArrowUp size={20} />
          </SendButton>
        </InputContainer>
      </Container>
      <SettingsPanel $isOpen={isSettingsOpen}>
        <CloseButton onClick={() => setIsSettingsOpen(false)}>
          <FaTimes />
        </CloseButton>
        <SettingsHeader>Settings</SettingsHeader>
        <SettingsForm onSubmit={handleSettingsSubmit}>
          <SettingsLabel htmlFor="aiName">AI Name</SettingsLabel>
          <SettingsInput
            id="aiName"
            value={aiName}
            onChange={(e) => setAiName(e.target.value)}
          />
          <SettingsLabel htmlFor="systemPrompt">System Prompt</SettingsLabel>
          <SettingsTextarea
            id="systemPrompt"
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
          />
          <SettingsLabel htmlFor="initialMessage">
            Initial Message
          </SettingsLabel>
          <SettingsTextarea
            id="initialMessage"
            value={initialMessage}
            onChange={(e) => setInitialMessage(e.target.value)}
          />
          <SettingsSaveButton type="submit">Save Settings</SettingsSaveButton>
        </SettingsForm>
      </SettingsPanel>
      {showResetConfirmation && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <FaExclamationTriangle /> Confirm Reset
            </ModalHeader>
            <ModalText>Are you sure you want to reset the chat?</ModalText>
            <ModalButtonContainer>
              <ModalButton onClick={cancelReset}>Cancel</ModalButton>
              <ModalButton onClick={confirmReset} $isDanger>
                Reset
              </ModalButton>
            </ModalButtonContainer>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

const MarkdownContent = styled(ReactMarkdown)`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;

  p {
    margin: 0 0 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  pre {
    background-color: hsl(180, 2%, 20%);
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
  }

  code {
    background-color: hsl(180, 2%, 20%);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-size: 0.85em;
  }

  ul,
  ol {
    margin: 0 0 1em;
    padding-left: 2em;
  }
`;

function App() {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <meta
          name="keywords"
          content="AI chat, Raiden Shogun, Genshin Impact, interactive conversation"
        />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={WEBSITE_URL} />
        <meta property="og:image" content={IMAGE_URL} />
        <link rel="canonical" href={WEBSITE_URL} />
        <link rel="icon" type="image/png" href={LOGO_URL} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Helmet>
      <GlobalStyle />

      <ChatComponent />
    </>
  );
}

export default App;
